@import 'sass/variables';

#izs_modal .ee_modal__content {
  padding: 10px 0;
  height: 500px;
  overflow-y: hidden;

  @media (min-width: $breakpoint-m) {
    height: 800px;
  }

  @media (min-width: $breakpoint-l) {
    height: 800px;
  }
}

#izs_modal .ee_modal__main {
  width: 350px;

  @media (min-width: 650px) {
    width: 600px;
  }

  @media (min-width: $breakpoint-m) {
    width: 700px;
  }

  @media (min-width: $breakpoint-l) {
    width: 950px;
  }
}

.izs_product-iframe {
  border: none;
  width: 100%;
  height: 100%;

  &__hidden {
    visibility: hidden;
  }
}

.izs_spinner-box {
  width: 100%;
  height: 100%;
  margin: 0;
}
