@import 'sass/variables';

.izs_ekomi_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  flex-direction: column;

  &__three-columns > .ee_ekomi-rating--with-seal {
    flex-direction: column;
  }

  &__three-columns .ee_ekomi-rating__seal {
    align-self: center !important;
  }

  @media (max-width: $breakpoint-m) {
    flex-direction: column;

    .ee_ekomi-rating__seal {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
