@import 'sass/variables';

.price__container {
  min-height: 280px;
  &__text {
    margin-top: 48px;
    @media (max-width: $breakpoint-m) {
      margin-top: 32px;
    }
  }

  &__bday {
    padding: 0 0 15px 0;
    display: flex;
    justify-content: center;

    &--empty {
      color: $color-paprika;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &__margin {
    &-lower {
      margin-bottom: 36px;
    }
  }

  &__zb,
  &__mtl {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    @media (min-width: $breakpoint-l) {
      font-size: 18px;
    }
  }

  &__zb {
    padding-right: 8px;
  }

  &__mtl {
    padding-left: 12px;
  }

  &__icon-to-top {
    color: $color-paprika;
    padding-left: 6px;
    cursor: pointer;
  }

  &__7-months {
    display: block;
    font-size: 12px;
    letter-spacing: 0.25px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 6px;
    @media (min-width: $breakpoint-l) {
      margin-top: 8px;
    }
  }

  &__spinner-wrapper {
    position: relative;
    height: 65px;
    @media (min-width: $breakpoint-l) {
      height: 78px;
    }
  }
}

.price__container__text-combination {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  @media (min-width: $breakpoint-l) {
    font-size: 18px;
    line-height: 24px;
  }
}

.price__container__value {
  min-height: 65px;
  margin: 16px 0;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  @media (min-width: $breakpoint-l) {
    font-size: 40px;
    line-height: 52px;
  }
}
