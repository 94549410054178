$color-white: #ffffff;

/* https://chir.ag/projects/name-that-color/#8E0038 */
$color-concrete: #f2f2f2;
$color-alto: #d9d9d9;
$color-grey: #333333;
$color-black: #000000;

/* https://chir.ag/projects/name-that-color/#8E0038 */
$color-paprika: #8e0038;

$color-optionBg: #e2f6fa;
$color-optionBgHover: #0f94a7;

/* taken from ergo elements 9.9.4*/
$breakpoint-xxl: 1440px;
$breakpoint-xl: 1152px;
$breakpoint-l: 912px;
$breakpoint-m: 768px;
$breakpoint-s: 480px;
$breakpoint-xs: 320px;
