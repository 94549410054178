@import 'sass/variables';

.multiple-choice__container {
  margin-top: 48px;
}

.multiple-choice__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  font-size: 18px;
  margin: 0;
  @media (min-width: $breakpoint-m) {
    font-size: 20px;
  }
  .ee_tooltip-icon__icon {
    color: $color-grey !important;
  }
}

.multiple-choice__subheader {
  font-size: 10px;
  margin: 0;
  padding: 4px 0 24px;
  @media (min-width: $breakpoint-l) {
    padding: 8px 0 32px;
    font-size: 14px;
  }
}

.multiple-choice__tooltip-text {
  white-space: pre-line;
  line-height: 1.5;
}
