@import 'sass/variables';

.izs_banners_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  @media (max-width: $breakpoint-m) {
    flex-direction: column;
    height: auto;
    gap: 30px;
  }
}
