@import 'sass/variables';

$choicer-height: 140px;
$choicer-padding: 20px;

$choicer-border-radius: 6px;

.choicer {
  &__shadow {
    font-size: 14px;
    line-height: 20px;
    max-width: 432px;
    @media (min-width: $breakpoint-s) {
      width: 408px;
    }
    @media (min-width: $breakpoint-m) {
      width: 348px;
    }
    @media (min-width: $breakpoint-l) {
      font-size: 16px;
      line-height: 24px;
      width: 408px;
    }
    margin: 0 auto;
    border-radius: $choicer-border-radius;
    /* taken fron invisionapp */
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.16), 0 6px 10px 0 rgba(0, 0, 0, 0.12),
      0 1px 18px 0 rgba(0, 0, 0, 0.1);
  }

  &__wrapper {
    border-radius: $choicer-border-radius $choicer-border-radius 0 0;
    background: $color-optionBg;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
  }

  &__body {
    background: $color-white;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $choicer-padding 0 $choicer-padding 22px;

    @media (max-width: $breakpoint-m) {
      flex-flow: row wrap;
    }

    &-tooltip {
      position: relative;
    }

    &-tooltip-text {
      white-space: pre-line;
      line-height: 1.5;
    }

    &-icon {
      margin-right: 12px;

      @media (max-width: $breakpoint-m) {
        margin-right: 0;
      }
    }

    &-text {
      width: 181px;
      text-align: left;

      @media (max-width: $breakpoint-m) {
        width: 200px;
      }
    }

    &-tooltip {
      .ee_tooltip-icon__icon {
        color: $color-grey !important;
      }
    }

    &-amount {
      width: 126px;
      font-weight: bold;

      @media (max-width: $breakpoint-m) {
        margin: 10px 0 0 30px;
        text-align: left;
      }
    }

    &--no-data {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__modal {
    color: $color-paprika;

    font-weight: bold;
    display: block;
    padding: 12px 0;
    cursor: pointer;
    border-top: 1px solid $color-alto;
    width: 90%;
    margin: 0 auto;
    @media (min-width: $breakpoint-l) {
      border-top: 0;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 148px;
    padding: $choicer-padding;
    position: relative;
    cursor: pointer;

    @media (max-width: $breakpoint-m) {
      width: 100%;
      padding: 5px;
    }

    &--active,
    &:hover {
      background: $color-optionBgHover;
      color: $color-white;

      // remove black lines from both sides of an active element
      &:before {
        width: 1px;
        background: $color-optionBgHover;
        position: absolute;
        left: -1px;
        top: 0;
        height: 100%;
        content: ' ';
      }

      &:after {
        width: 0 !important;
      }
    }

    &--disabled {
      background: $color-concrete;
      cursor: not-allowed;

      &:hover {
        background: unset !important;
        color: unset !important;
      }

      /* TODO: i think there's a more BEM way to do this, get back here someday */
      & > div {
        opacity: 0.6;
      }
    }

    &:first-child {
      border-top-left-radius: 0px;
    }

    &:last-child {
      border-top-right-radius: 0px;
    }

    &:last-child:after {
      display: none;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      background: $color-black;
      right: 0;
      height: 100px;
      transform: translateY(-50%);
      top: 50%;
    }

    &-title {
      display: flex;
      align-items: center;
      height: 72px;
      font-weight: bold;
      letter-spacing: 0;
    }

    &-radio {
      /* ergo-elements has a "bug" and doesnt not center the radio input  */
      padding-left: 10px;
      pointer-events: none;
    }

    .ee_radio__control:checked + .ee_radio__label::before {
      border-color: white !important;
    }
    .ee_radio__control:checked + .ee_radio__label::after {
      border-color: white !important;
      background: white !important;
    }

    .ee_radio__control:focus ~ .radio__label::before {
      border-color: white !important;
      background: white !important;
    }
  }

  &__button {
    .ee_button__content {
      font-size: 14px;
      @media (min-width: $breakpoint-l) {
        font-size: 16px;
      }
    }
  }
}
.choicer__description-wrapper {
  min-height: 264px;
}
.choicer__description-item {
  display: flex;
  padding: 18px 12px 14px 14px;
  justify-content: space-between;
  flex-direction: column;
  &:nth-child(2n) {
    background: $color-concrete;
  }
  @media (min-width: $breakpoint-l) {
    flex-direction: row;
  }
}

.choicer__description-item-icon-text-wrapper {
  display: flex;
  justify-content: space-between;
  @media (min-width: $breakpoint-l) {
    width: 250px;
  }
  & .choicer__description-tooltip {
    top: -10px;
  }
}

.choicer__description-item-icon-text {
  display: flex;
  align-items: initial;
}

.choicer__description-item-text {
  @media (min-width: $breakpoint-l) {
    min-width: 220px;
  }
  @media (min-width: $breakpoint-s) {
    min-width: 180px;
    max-width: 180px;
  }
  padding-top: 3px;
  text-align: left;
  padding-left: 10px;
  line-height: 20px;
}

.choicer__description-item-value {
  font-weight: bold;
  text-align: left;
  padding-left: 33px;
  padding-top: 8px;
  @media (min-width: $breakpoint-l) {
    width: 126px;
    border-left: 1px solid $color-black;
    padding-left: 0;
    text-align: center;
  }
}

.choicer__description--no-data-wrapper {
  height: 300px;
  @media (min-width: $breakpoint-l) {
    height: 264px;
  }
}
