.birthday__hint-control {
  width: 100%;
}

.birthday__wrapper {
  display: flex;
  justify-content: center;
  margin: 42px auto 0 auto;
  max-width: 380px;
}
