@import 'sass/variables';

.izs_trust-banner_element-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  position: relative;

  &__three-columns {
    flex-direction: column;
  }
}

.izs_trust-banner_text-content {
  text-align: left;
}

.izs_banner_warranty-img {
  z-index: 2;
  max-width: 134px;
  max-height: 134px;
  box-shadow: transparent 0px 0px 2px;
}
.izs_banner_trust-img {
  z-index: 2;
  max-width: 190px;
  max-height: 134px;
  box-shadow: transparent 0px 0px 2px;
}

.izs_trust-banner_description {
  font-size: 14px;
  margin: 0;
}
.izs_trust-banner_header {
  font-size: 18px;
  font-weight: 700;
  margin: 8px 0;
}

@media (max-width: $breakpoint-m) {
  .izs_trust-banner_element-container {
    flex-direction: column;
    gap: 10px;
  }
  .izs_trust-banner_element-container:last-child {
    margin-top: 40px;
  }

  .izs_trust-banner_text-content {
    text-align: center;
  }

  .izs_trust-banner_description {
    font-size: 12px;
  }
  .izs_trust-banner_header {
    font-size: 16px;
    margin: 4px 0;
  }
  .izs_trust-banner_content {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .izs_trust-banner_description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .izs_trust-banner_description {
    margin: 12px 0 6px 0;
  }
  .banner_warranty_header {
    margin: 0 0 16px;
  }
}
