@import 'sass/variables';

.sticky-header {
  position: fixed;
  width: 100vw;
  left: 0;
  background: $color-optionBg;
  padding: 24px 0;
  opacity: 0;
  display: none;
  z-index: 100;

  @media (max-width: $breakpoint-m) {
    visibility: hidden;
    display: block;
  }

  &--active {
    top: 0;
    opacity: 1;

    @media (max-width: $breakpoint-m) {
      visibility: visible;
    }
  }

  &--replaced {
    top: 64px;
  }

  &__esc_h5 {
    margin-bottom: 0 !important;
  }

  &__wrapper {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  &__text {
    font-size: 0.8rem;
  }

  &__price {
    font-size: 1.3rem;
    font-weight: bold;
    flex-grow: 1;
  }

  &__mtl {
    padding-left: 4px;
  }

  &__spinner-wrapper {
    position: relative;
    height: 50px;
    background-color: #e2f6fa;
  }
}

#stickyHeaderSpinner.ee_loading-spinner {
  background-color: inherit;
}
